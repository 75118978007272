<template>
  <v-app>
    <div class="row">
      <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>
                    Manage staff
                  </h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard </router-link>\ Manage staff
                  </div>
                </div>
                <div class="breadcrumb-right">
                  <div class="card-toolbar">
                    <v-btn @click="createSystemUser()"
                         class="mt-4 btn btn-primary mr-4"
                         dark>
                    <i class="fa fa-plus"></i>
                    Add staff
                  </v-btn>
                    <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon mt-4" no-caret right no-flip>
                      <template v-slot:button-content>
                        <i class="ki ki-bold-more-hor"></i>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover">
                        <b-dropdown-text tag="div" class="navi-item">
                          <a @click="import_excel = !import_excel" class="navi-link">
                          <span class="navi-icon">
                            <i class="fa fa-file-excel"></i>
                          </span>
                            <span class="navi-text">Import</span>
                          </a>
                        </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('import-export', 'import-student')">
                          <a @click="exportData"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                            <span class="navi-text">Export</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="search-wrapper border-outline mb-5  py-5 d-sm-flex justify-content-start" v-if="import_excel">
                <div class="row px-4">
                  <div class="col-12">
                    <h4>Upload file to update staff details</h4>
                  </div>
                  <div class="col-8">
                    <v-file-input outlined dense v-model="excel_file" accept=".xlsx, .xls, .csv"
                                  label="File input"></v-file-input>
                  </div>
                  <div class="col-4">
                    <v-btn @click="importStaff" :loading="isLoading" :disabled="!excel_file"
                           class="btn btn-primary ml-2 text-white">Import
                    </v-btn>
                    <v-btn @click="cancelStaffImport"
                           class="btn btn-primary ml-2 text-white">Cancel
                    </v-btn>
                  </div>
                  <div class="col-12 text-xs">

                  </div>
                  <div class="col-12 text-xs">
                    <b>Note</b>
                    <ul class="ml-3">
                      <li>Sample excel file can be downloaded for individual.<a @click="sampleExport()">Download sample</a></li>
                      <li>Staff will be created only.It will not updated staff from import file.</li>
                      <li>Following fields are created on bulk.</li>
                      <li>First name, Last name, Mobile, Email, Password are required.</li>
                      <li>If password is empty then default password will be <b class="font-size-lg">password</b>.</li>
                      <li>There can only be 50 staffs created form import file.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <v-row class="mb-4" v-if="import_error.length > 0">
                <div class="col-12">
                  <b-list-group>
                    <b-list-group-item variant="danger">
                      <div class="row" v-for="(error, index) of import_error" :key="index">
                        <div class="col-4">
                          <strong>{{ error.user_name }}</strong>
                          <br>
                          <strong>{{ error.email }}</strong>
                        </div>
                        <div class="col-8">
                          <ul class="">
                            <li v-for="(item, i) of error.issues.split(',')" :key="i" v-show="item">
                              <small>{{ item }}</small>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </v-row>

              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start" v-if="!import_excel">
                <div class="row px-2">
                  <div class="col-lg-3">
                    <v-text-field
                        v-model="search.name"   v-on:keyup.enter="getUsers()"
                        label="Name, email or phone"
                        outlined
                        clearable
                        dense>
                    </v-text-field>

                  </div>
                  <v-col cols="12" md="2">
                    <v-select v-on:keyup.enter="getUsers()" :items="levels" class="form-control" v-model="search.level_id"
                              label="Level" item-value="id" item-text="title" outlined clearable @change="getPrograms" dense>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select clearable outlined dense class="form-control" v-model="search.program_id" :items="programs"
                              label="Programs" v-on:keyup.enter="getUsers()" item-value="id" item-text="title">
                    </v-select>
                  </v-col>
                  <div class="col-lg-2">
                    <v-select
                        outlined  v-on:keyup.enter="getUsers()"
                        v-model="search.type"
                        dense
                        :items="job_types"
                        item-value="name"
                        clearable
                        item-text="role_name"
                        label="Type">

                    </v-select>

                  </div>
                  <v-col cols="2" class="text-right">
                    <v-btn
                        @click="getUsers()"
                        :loading="loading"
                        class="btn btn-primary w-35 float-right" dark
                    >
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <div class="">
                <v-row>
                  <v-col cols="12" v-if="selected.length > 0">
                    <v-card flat color="grey lighten-4">
                      <v-toolbar flat color="grey lighten-4">
                        <v-toolbar-title>Total Selected : {{ selected.length }}</v-toolbar-title>
                        <v-toolbar-title class="ml-5"> Actions :</v-toolbar-title> &nbsp; &nbsp;
                        <v-btn depressed @click.prevent="openChangePassDialog()">
                          <v-icon>assignment_ind</v-icon>&nbsp;
                          Change password

                        </v-btn>

                      </v-toolbar>
                    </v-card>

                  </v-col>
                </v-row>
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25"
                ></v-skeleton-loader>
                <table class="table" v-if="!loading">
                  <thead>
                    <tr class="px-3">
                      <th class="" style="width: 40px !important; white-space: pre-wrap;">
                        <v-checkbox v-if="users.length > 0" class="id-card__checkbox" @change="selectAll()" v-model="checkAll"></v-checkbox>
                      </th>
                      <th class="px-3 wrap-column" colspan="2" style="width: 40px !important; white-space: pre-wrap;">Staff info</th>
                      <th class="px-3 wrap-column">First name</th>
                      <th class="px-3 wrap-column">Middle name</th>
                      <th class="px-3 wrap-column">Last name</th>
                      <th class="px-3 wrap-column">Status</th>
                      <th class="pr-3 text-center">Action</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in users" :key="item.id">
                      <td class="px-3 wrap-column" style="width: 40px !important; white-space: pre-wrap;">
                        <v-checkbox v-model="selected" :value="item"></v-checkbox>
                      </td>
                      <td class="px-3 wrap-column" style="width: 40px !important; white-space: pre-wrap;">
                        <div class="symbol-label mt-2">
                          <img v-if="item.image_path && item.image_path.real"
                               :src="item.image_path.real"
                               class="cursor-pointer" alt=""
                               @click="changeImage(item)"
                               style="height: 30px;">
                          <span v-else class="symbol symbol-35 symbol-light-success" @click="changeImage(item)">
                            <span class="symbol-label font-size-sm font-weight-bold cursor-pointer">
                              {{item.first_name ? item.first_name.charAt(0).toUpperCase() : '' }}
                              {{ item.last_name ? item.last_name.charAt(0).toUpperCase() : '' }}</span>
                          </span>
                        </div>
                      </td>
                      <td class="px-3 wrap-column">
                        <a href="#!" @click.prevent="openSummaryPage(item)" class="mb-1">{{item.personal_id }}</a>
                        <div class="text-secondary" v-if="item.setting && item.setting.midas_id">
                          <b>External ID:</b>  {{item.setting.midas_id!=null  ? item.setting.midas_id : 'N/A'}}
                        </div>
                      </td>
                      <td class="px-3 wrap-column">
                        <span href="#!"  class="mb-1">
                          {{item.first_name }}
                        </span>
                      </td>
                      <td class="px-3 wrap-column">
                        <span   class="mb-1">
                          {{item.middle_name }}
                        </span>
                      </td>
                      <td class="px-3 wrap-column">
                        <span   class="mb-1">
                          {{item.last_name }}
                        </span>
                      </td>
                      <td class="px-3 wrap-column">
                        <div class="mt-2">
                          <span class="badge badge-success text-lg "
                                v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }">
                            {{ item.is_active ? 'Active' : 'Inactive' }}
                          </span>
                        </div>
                      </td>
                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click="openSummaryPage(item)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click="manageUserRole(item.id)">
                                  <span class="navi-icon">
                                    <i class="fas fa-user-alt"></i>
                                  </span>
                                  <span class="navi-text">Manage Role</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text tag="div" class="navi-item">
                                <a
                                  href="#"
                                  class="navi-link"
                                  @click="deleteUser(item.id)"
                                >
                                  <span class="navi-icon">
                                    <i class="flaticon-delete"></i>
                                  </span>
                                  <span class="navi-text"> Delete </span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="users.length == 0">
                      <td colspan="8" class="text-center">
                        <strong>Data not available.</strong>
                      </td>
                    </tr>
                  </tbody>

                </table>
                <b-pagination v-if="users.length > 0" class="pull-right mt-7" @input="getUsers" v-model="page"
                              :total-rows="total" :per-page="perPage" first-number :loading="isLoading" last-number></b-pagination>
              </div>
      </div>
          </div>
      </div>
    </div>
    <v-dialog v-model="changePasswordDialog" max-width="600">
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            Change bulk password
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="hideDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-text-field
              outlined
              v-model="password"
              dense
              label="Password"
          >

          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-col cols="12">
            <div class="float-right">
              <v-btn depressed class="btn btn-standard" @click="hideDialog">Cancel</v-btn>
              <v-btn depressed :loading="changeLoading" class="btn btn-primary text-white ml-2"
                     @click.prevent="changeBulkPassword">Change Password
              </v-btn>
            </div>
          </v-col>


        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadDialog" max-width="800">
          <v-card>
            <v-card-title >
              Update profile
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <h4>Personal information</h4> <hr/>
                </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="selected_user.first_name" outlined dense label="First name"></v-text-field>
                    <span class="text-danger" v-if="$v.selected_user.first_name.$error">First name is required</span>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="selected_user.middle_name" outlined dense label="Middle name"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="selected_user.last_name" outlined dense label="Last name"></v-text-field>
                    <span class="text-danger" v-if="$v.selected_user.last_name.$error">Last name is required</span>
                  </v-col>
                <v-col cols="12">
                  <h4>Contact information</h4> <hr/>
                </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="selected_user.mobile" outlined dense label="Contact mobile"></v-text-field>
                    <span class="text-danger" v-if="$v.selected_user.mobile.$error">Mobile is required</span>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="selected_user.phone" outlined dense label="Contact phone"></v-text-field>
                  </v-col>
                <v-col cols="12">
                  <h4>Identity Card information</h4> <hr/>
                </v-col>
                  <v-col cols="4">
                    <v-text-field v-model="selected_user.setting.id_card_address" outlined dense
                      label="ID card address"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input @change="changePreviewImage" :rules="rules" v-model="user.photo" outlined
                      prepend-icon="" @click:clear="previewImage = null" dense accept="image/png, image/jpeg, image/bmp"
                      prepend-inner-icon="mdi-camera" label="Image"></v-file-input>
                  </v-col>
                  <v-col cols="4" v-if="user">
                    <div class="symbol-label">
                      Image preview<br/>
                      <img v-if="previewImage" :src="previewImage" class="" alt="" style="height: 150px; width: 200px; object-fit:contain;">
                      <span v-else class="symbol  symbol-light-success ">
                        <span class="symbol-label font-size-h6 font-weight-bold p-5">
                          NA</span>
                      </span>
                    </div>
                  </v-col>

              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-col cols="12">
                <div class="float-right">
                  <v-btn @click="cancelAction" class="btn btn-standard cancel-btn" depressed>Cancel
                  </v-btn>
                  <v-btn class="text-white ml-2 btn btn-primary" depressed @click="updateUser" :loading="isBusy">Update
                  </v-btn>
                </div>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </v-app>
</template>
<script>
import UserService from "@/core/services/user/UserService";
import RoleService from "@/core/services/user/role/RoleService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import { required } from "vuelidate/lib/validators";
import {API_URL} from "@/core/config";

const program=new ProgramService();
const level=new LevelService()
const userService = new UserService();
const roleService = new RoleService();

export default {
  name: "users",
  components: {},
  validations: {
    selected_user: {
      last_name: { required },
      first_name: { required },
      mobile: { required },
    },
  },
  data() {
    return {
      excel_file: null,
      import_error: [],
      users: [],
      selected: [],
      password: null,
      loading: false,
      import_excel: false,
      attrs: false,
      changeLoading: false,
      changePasswordDialog: false,
      isLoading: false,
      job_types: [],
      page: null,
      total: null,
      perPage: null,
      closeOnClick: false,
      checkAll: false,
      levels:[],
      programs:[],
      selected_user: {
        photo: null,
        last_name: null,
        first_name: null,
        middle_name: null,
        mobile: null,
        phone: null,
        setting: {}
      },
      user: {
        photo: null,
        last_name: null,
        first_name: null,
        middle_name: null,
        mobile: null,
        phone: null,
        setting: {}
      },
      uploadDialog: false,
      previewImage: null,
      rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      isBusy: false,
      search: {
        type: "staff",
        name: '',
        level_id:null,
        program_id:null,
      },
    };
  },
  mounted() {
    this.getUsers();
    this.getRoles();
    this.getLevels()
  },
  methods: {
    sampleExport() {
      let url = API_URL + "user/export/template/teacher";
      window.open(url);
    },
    getUsers() {
      this.loading = true
      userService
          .paginate(this.search, this.page)
          .then(response => {

              this.users = response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.perPage = response.data.meta.per_page;

              if(this.selected.length == 0){
                this.selected=[];
              }
              // alert(this.length)
          }).catch(erro => {

          })
          .finally(() => {
            this.loading=false;
          })
    },
    getLevels() {
      level.all().then(response => {
        this.levels = response.data;
        if (this.search.id && this.search.level_id) {
          this.getPrograms();
        }
      });
    },
    getPrograms() {
      program
          .getAllByLevelId(this.search.level_id).then(response => {
              this.programs = response.data;
          })
          .catch((err) => {

          })
          .finally(() => {

          });
    },
    openChangePassDialog() {
      this.changePasswordDialog = true;
    },
    hideDialog() {
      this.password = null;
      this.changePasswordDialog = false;
    },
    editUser(id) {
      return this.$router.push({name: "user-update", params: {id: id}});
    },
    changeBulkPassword() {
      this.buildPassswordData();
      this.changeLoading = true;
      userService.bulkUpdatePassword(this.passwordData).then(response => {
        this.$snotify.success("Information updated!!!")
      }).catch(error => {
        this.$snotify.error("Error occurred in bulk password update")
      }).finally(() => {
        this.changeLoading = false;
        this.hideDialog();

      })

    },
    buildPassswordData() {
      if (this.selected.length) {
        this.passwordData = this.selected.map(user => {
          let data = {
            id: user.id,
            password: this.password,
            first_name: user.first_name,
          }
          return data;
        })
      }
    },
    resetFilter() {
      this.search = {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        type: 'staff',
        limit: 25
      }
    }
    , getRoles() {
      roleService.all().then(response => {
        this.job_types = response.data.data
      });
    },
    deleteUser(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            userService
                .delete(id)
                .then(response => {
                  this.$snotify.success("Information deleted");
                  this.getUsers();

                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    },
    manageUserRole(id) {
      this.$router.push({name: "user-role", params: {id, id}});
    },
    createSystemUser(){
      this.$router.push({
        name: 'user-new'
      })
    },
    openSummaryPage(item){
      this.$router.push({
        name: "students-summary",
        params: { id:item.id, type:item.type[0] }
      });
    },
    selectAll() {
      if (!this.checkAll) {
        this.selected = [];
      } else {
        this.users.forEach(user => {
          this.selected.push(user);
        })

      }
    },
    exportData(){
      userService.exportStaff(this.search)
    },
    changeImage(user) {
      this.selected_user = user;

      if(user && user.image_path){
        this.previewImage = user.image_path['thumb'];
      }

      if (user && user.setting) {
        this.selected_user.setting.id_card_address = user.setting.id_card_address
      }

      this.uploadDialog = true;
    },

    changePreviewImage() {
      this.previewImage = URL.createObjectURL(this.user.photo)
    },

    generateFd() {
      let fd = new FormData();
      for (let key in this.user) {
        if (
          key == "photo" &&
          this.user["photo"] &&
          this.user["photo"] != null
        ) {
          if (typeof this.user["photo"] == "object") {
            fd.append("photo", this.user[key]);
          }
        } else if (key == "notification_email" && this.user["notification_email"]) {
          fd.append("notification_email", this.user.notification_email ? 1 : 0);
        } else if (key == "notification_sms") {
          fd.append("notification_sms", this.user.notification_sms ? 1 : 0);
        } else if (key == "notify_parent") {
          fd.append("notify_parent", this.user.notify_parent ? 1 : 0);
        } else if (key == "com_pref_postal") {
          fd.append("com_pref_postal", this.user.com_pref_postal ? 1 : 0);
        } else if (key == "notify_other") {
          fd.append("notify_other", this.user.notify_other ? 1 : 0);
        } else {
          if (key != "id" && this.user[key]) {
            fd.append(key, this.user[key]);
          }
        }
      }
      return fd;
    },

    updateUser() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000)
        return
      }
      delete this.user.setting
      let fd = this.generateFd();
      this.isBusy = true;
      userService
        .update(this.selected_user.id, this.selected_user)
        .then(response => {

          if (this.user.photo != null) {
            this.uploadImage();

          } else {
            this.$snotify.success("Information updated");
            this.resetUploadDialogData();
            this.getUsers();
          }
          this.uploadDialog = false;
          this.isBusy = false;
        })
        .catch(error => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },

    uploadImage() {
      let formData = this.convertToFormData();
      userService
        .update(this.selected_user.id, formData)
        .then(response => {

          this.$snotify.success("Information updated");
          this.resetUploadDialogData();
          this.getUsers();
        })
        .catch(error => {
          this.isBusy = false;
          this.$snotify.error("Something Went Wrong");
        });
    },

    convertToFormData() {
      let formData = new FormData();
      let upload_image = {};
      upload_image.photo = this.user.photo;
      for (let key in upload_image) {
        if (key === "photo" && upload_image[key] != null && upload_image[key] != undefined) {
          formData.append('photo', upload_image[key]);
        } else {
          if (upload_image[key]) {
            formData.append(key, upload_image[key]);
          }
        }
      }
      return formData;
    },

    resetUploadDialogData() {
      this.selected_user = {
        photo: null,
        last_name: null,
        first_name: null,
        middle_name: null,
        mobile: null,
        phone: null,
        setting: {}
      };
      this.previewImage = null;
    },

    cancelAction() {
      this.uploadDialog = false;
      this.resetUploadDialogData();
    },

    closeUploadDialog() {
      this.uploadDialog = false;
      this.resetUploadDialogData();
      this.getUsers();
    },

    cancelStaffImport(){
      this.excel_file = null;
      this.import_excel = false;
    },
    importStaff() {
      this.isLoading = true
      let fd = new FormData();
      fd.append("file", this.excel_file);
      this.import_error = [];
      userService.importStaff(fd).then(res => {
        if(res.data.errors) {
          this.import_error = res.data.errors
        }
        if(res.data.status =="OK") {
          this.isLoading = false
          this.excel_file = null;
          this.import_excel = false;
          this.$snotify.success("File import success");
        }
        this.getUsers();

      }).catch(() => {
        this.isLoading = false;
        this.$snotify.error("File import Failed");
      }).finally(() => {
        this.isLoading = false;
      });
    },
  }
};
</script>
